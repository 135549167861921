import React, { useState } from 'react';
import { Footer, Navbar, Testimonials } from './components';
import Typewriter from 'typewriter-effect';
import { Carousel } from 'antd';
import WagyuFoodsCarousel from "./components/carousel/Carousel";
import { routesConstants } from '../utility/routesConstants';
import { Link } from 'react-router-dom';
import Certificates from './components/Certificates';
import WhoWeAre from './components/WhoWeAre';
import { Button, Modal } from 'antd';
import ReportCertificates from './components/ReportCertificates';

function Team() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [open, setOpen] = useState(true);

  const showModall = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [showModal, setShowModal] = React.useState(false);

  return (
    <div className="App">
      <div id="page" className="page">
        <Navbar />
        <br /><br /><br />
        <section
          id="features-3"
          className="bg-fixed wide-30 featuress-section division"
          style={{
            backgroundImage: "url(images/tra-waves.png)",
            backgroundPosition: "center bottom"
          }}
        >
          <div className="container">
            {/* SECTION TITLE 	*/}
            <div className="row">
              <div className="col-md-12 section-title">
                {/* Title */}
                <h2 className="h2-lg">Our Dedicated Team</h2>
                {/* Text */}
                <p>
                  Our team is comprised of passionate professionals who bring together a wide range of expertise to drive innovation and excellence. From software development to strategic planning, each member plays a vital role in ensuring the success of our projects. We work seamlessly to deliver high-quality solutions, always focused on achieving our goals and providing exceptional value to our clients and partners.
                </p>
              </div>
            </div>{" "}



            <div className="row">
              {/* TEAM MEMBER #3 */}



              <div className="col-md-6 col-lg-6 animated" data-animation="fadeInUp" data-animation-delay={500}>
                <div className="fbox-3 box-icon-lg green-hover">
                  <div className="box-line" />
                  <img
                    style={{
                      borderRadius: '10px',
                      width: '180px',
                      height: '180px',
                      objectFit: 'cover'
                    }}
                    src="./img/team/olaseni.jpg" alt="Salako Olaseni Mujeeb" className="team-img" />
                  <h5 className="h5-md">Salako Olaseni</h5>
                  <h6 className="h5-md">Executive Director</h6>
                  <p className="grey-color">

                    SALAKO, Olaseni Mujeeb. Joined UACN's Agro Industries Division in 1985 as Assistant Livestock Manager. Rose through the ranks  as Livestock Manager, Farm Manager, Marketing Manager. Transferred to UAC Foods Division in 1998 as Operations Manager, Acting Agro Director, Divisional Director - Meat Products, Divisional Customer Service Director, Divisional Agro Director. Moved over to Zartech Limited as General Manager, later moved to Best Foods as Managing Director from where I retired in December 2023.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 animated" data-animation="fadeInUp" data-animation-delay={600}>
                <div className="fbox-3 box-icon-lg green-hover">
                  <div className="box-line" />
                  <img
                    style={{
                      borderRadius: '10px',
                      width: '180px',
                      height: '180px',
                      objectFit: 'cover'
                    }}
                    src="./img/team/iganya.jpg" alt="Iganya Samson Noah" className="team-img" />
                  <h5 className="h5-md">Iganya Samson Noah </h5>
                  <h6 className="h5-md">General Manager</h6>

                  <p className="grey-color">
                    Iganya Samson Noah did National Youth Service in 1990/1991. He joined UAC Agro Industries in 1992 as a livestock supervisor. He became assistant poultry manager in 1997 and rose to a full managerial position in 1998 as poultry manager. He was elevated to livestock operations manager in the UAC Foods Division of UACN. He later joined Ayokunle Farms as the general manager for 5 years before moving to Food Concept. He then joined Quality King Agro Industries as the general manager and resigned from Pally Agro Industries in 1992.                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 animated" data-animation="fadeInUp" data-animation-delay={400}>
                <div className="fbox-3 box-icon-lg green-hover">
                  <div className="box-line" />
                  <img
                    style={{
                      borderRadius: '10px',
                      width: '180px',
                      height: '180px',
                      objectFit: 'cover'
                    }}
                    src="./img/team/awelewa.jpg" alt="Gbolabo Awelewa" className="team-img" />
                  <h5 className="h5-md">Gbolabo Awelewa</h5>
                  <h6 className="h5-md">Chief Technical Officer</h6>

                  <p className="grey-color">

                    Gbolabo Awelewa, Chief Solutions Officer (CSO) at Cybervergent,
                    brings nearly two decades of expertise in Business Information
                    Systems and Enterprise Security. With a strong track record in cybersecurity
                    roles, he has led security initiatives
                    for Fintech Unicorns, Multinationals, and Financial Institutions,
                    focusing on profitable and secure technology management.

                    Passionate about Generative AI and Blockchain. Gbolabo holds
                    numerous certifications, including C|CISO, CISM, CHFI, and ISO
                    27001 Lead Implementer, alongside a B.Sc. in Electronics and
                    Computer Engineering, an MBA, and Harvard Executive Education
                    in cybersecurity.

                  </p>
                </div>
              </div>



              {/* TEAM MEMBER #1 */}
              <div className="col-md-6 col-lg-6 animated" data-animation="fadeInUp" data-animation-delay={300}>
                <div className="fbox-3 box-icon-lg green-hover">
                  <div className="box-line" />
                  <img
                    style={{
                      borderRadius: '10px',
                      width: '180px',
                      height: '180px',
                      objectFit: 'cover'
                    }}
                    src="./img/team/emmanuel.jpg" alt="Fadipe Emmanuel" className="team-img" />
                  <h5 className="h5-md">Fadipe Emmanuel</h5>
                  <h6 className="h5-md">Senior Software Engineer</h6>
                  <p className="grey-color">


                    Emmanuel Fadipe is an accomplished software engineer with over
                    17 years of diverse experience spanning the Banking, Fintech,
                    Insurance, and Edutech sectors. He possesses a unique blend of
                    technical expertise, leadership, and innovative thinking.
                    Throughout his career, Emmanuel has successfully led cross-functional teams,
                    driving product development and solving complex challenges in dynamic and
                    fast-paced environments. His extensive background enables
                    him to deliver high-quality solutions, making significant
                    contributions to each industry he engages with, ensuring both
                    technological advancement and business growth.


                  </p>
                </div>
              </div>

              {/* TEAM MEMBER #2 */}




              {/* TEAM MEMBER #4 */}


              {/* TEAM MEMBER #5 */}
              <div className="col-md-6 col-lg-6 animated" data-animation="fadeInUp" data-animation-delay={600}>
                <div className="fbox-3 box-icon-lg green-hover">
                  <div className="box-line" />
                  <img
                    style={{
                      borderRadius: '10px',
                      width: '180px',
                      height: '180px',
                      objectFit: 'cover'
                    }}
                    src="./img/team/clement.jpg" alt="Omolayo Clement" className="team-img" />
                  <h5 className="h5-md">Clement Omolayo </h5>
                  <h6 className="h5-md">Senior Software Engineer</h6>
                  <p className="grey-color">
                    Clement Omolayo is a dedicated Software Engineer with
                    a strong background in software development,
                    specializing in building scalable applications. With experience leading
                    software teams and managing projects from conception to deployment,
                    he has a track record of delivering high-quality solutions efficiently.
                    Clement’s expertise spans various technologies.
                    He graduated with distinctions in computer science and Information Technology,
                    showcasing his passion for continuous learning and innovation in tech.
                  </p>
                </div>
              </div>

            </div>
            {/* END SECTION TITLE */}

            {/* End row */}
          </div>{" "}
          {/* End container */}
        </section>{" "}
        <Footer />

      </div>
    </div>
  );
}
export default Team;
