import React from 'react';

function WhoWeAre(props: any) {
  return <>

    <section id="faqs-1" className="bg-fixed wide-80 faqs-section division">
      <div className="container">

        <div className="row">
          <div className="col-md-10 offset-md-1 section-title">

            <h2 className="h2-lg">Who We Are</h2>

          </div>{" "}

        </div>{" "}

        <div className="row">

          <div className="col-lg-10 m-auto center text-center align-center">
            <div className="questions-holder ind-30">

              We aim to enhance the meat export system across the country, from the ranch to the Nigerian customs service at all International Airports. We have Digitalized the whole meat value chain by enabling state veterinarians to issue digital birth certificates,
              slaughter certificates( health certificates), and customs export certificates.This entire process brings transparency to the meat industry in Nigeria. We will also build abattoirs to the Food and Agricultural Organization (FAO) standard.
              <br /><br />
              Wagyu Foods and Tech is a first-of-its-kind agri-tech startup in Nigeria. We are equipped with technologies and infrastructure to facilitate the traceability of locally sourced meat from ranches and abattoirs across the state to foreign off-takers for export purposes via our App.
              <br /><br />
              Our effort enables the standardization of food/product tracking during red meat production, distribution, and supply chain in Nigeria.
              <br /><br />
              With the exclusive right to operate our fully patented technology (ProteinTrail) across the country, our solution is an effective and seamless monitoring process for stakeholders and veterinary authorities in each state, government inspectors, meat distributors, meat recipients (food business, etc), and end consumers.
            </div>
          </div>{" "}

        </div>{" "}

      </div>{" "}

    </section>{" "}

  </>;
}
export default WhoWeAre;
